import { Box, CircularProgress, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import mainTheme from "../css/themes/mainTheme";
import useQuery from "../hooks/useQuery";
import App from "./app";
import { useEffect, useState } from "react";
import { GetDeviceById } from "../helpers/dataHandlers/Narrowcasting";
import { closeSnackbar, useSnackbar } from "notistack";
import Fade from "../components/snackbarTransitions/Fade";
import QueueHandler from "../helpers/queueHandler";
import { DeepCopy, getNextIndex, isEmpty, isSame } from "../helpers/objectHelpers";
import ScheduleEmpty from "../components/scheduleEmpty";
import Splitscreen from "../components/splitscreen";

let sortedViews;
let screen0Views;
let screen1Views;
let screen2Views;
let screen3Views;

function Player() {
  const query = useQuery();
  const device_id = query.get('deviceId');
  const admin_id = query.get('adminId');
  const location_id = query.get('locationId');
  const queueHandler = QueueHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [device, setDevice] = useState({});
  const [currentPlaylist, setCurrentPlaylist] = useState({});
  const [view, setView] = useState({});
  const [view1, setView1] = useState({});
  const [view2, setView2] = useState({});
  const [view3, setView3] = useState({});
  const [nextViewIndex, setNextViewIndex] = useState(-1);
  const [nextView1Index, setNextView1Index] = useState(-1);
  const [nextView2Index, setNextView2Index] = useState(-1);
  const [nextView3Index, setNextView3Index] = useState(-1);
  const [rotation, setRotation] = useState("rotate-0");
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [retry, setRetry] = useState(false);
  const [scheduleEmpty, setScheduleEmpty] = useState(false);
  const playlistQueue = queueHandler.getFullQueue();
  const [layout, setLayout] = useState("");
  const [aspect, setAspect] = useState(false);
  

  useEffect(() => {
    if (!device || isEmpty(device)) return

    switch (device.screen_rotation) {
      case 'portrait':
        setRotation("rotate-90")
        break;
      case 'landscape-flipped':
        setRotation("rotate-180")
        break;
      case 'portrait-flipped':
        setRotation("rotate-270")
        break;
      case 'landscape':
      default:
        setRotation("rotate-0")
        break;
    }

    setScheduleEmpty(queueHandler.isTodayScheduleEmpty(device))

    if (!scheduleEmpty) {
      queueHandler.createQueue(device)
    }
    // eslint-disable-next-line
  }, [device])

  useEffect(() => {
    // Function to update the time and set the next timeout
    const updateAndScheduleNextPlaylist = () => {
      const now = new Date();

      const newPlaylist = queueHandler.getCurrentPlaylist();
      if (!isSame(newPlaylist, currentPlaylist)) setCurrentPlaylist(newPlaylist);
      
      // Calculate the time remaining until the next minute change
      const msUntilNextMinute = (60 - now.getSeconds()) * 1000;
      setTimeout(updateAndScheduleNextPlaylist, msUntilNextMinute);
    };

    // Initial call to set the first timeout
    updateAndScheduleNextPlaylist();
    
    // Cleanup function to clear timeouts when component unmounts
    return () => clearTimeout(updateAndScheduleNextPlaylist);
    // eslint-disable-next-line
  }, [playlistQueue])

  // sorting the current playlist into order and assigning them to screen arrays
  useEffect(() => {
    if (currentPlaylist && currentPlaylist.views) {
      setLayout(currentPlaylist.layout);
      setAspect(currentPlaylist.preserve_aspect);
      sortedViews = DeepCopy(currentPlaylist).views.sort((a, b) => a.position_order - b.position_order);
      console.log(sortedViews);
      screen0Views = sortedViews.filter((view) => view.position === 0);
      screen1Views = sortedViews.filter((view) => view.position === 1);
      screen2Views = sortedViews.filter((view) => view.position === 2);
      screen3Views = sortedViews.filter((view) => view.position === 3);
      console.log(screen0Views);
      console.log(screen1Views);
      console.log(screen2Views);
      console.log(screen3Views);
    
      // if (currentPlaylist.layout !== "fullscreen") {
      //   screen0Views = sortedViews.filter((view) => view.position === 0);
      //   screen1Views = sortedViews.filter((view) => view.position === 1);
      //   screen2Views = sortedViews.filter((view) => view.position === 2);
      //   screen3Views = sortedViews.filter((view) => view.position === 3);
      //   console.log(screen0Views);
      //   console.log(screen1Views);
      //   console.log(screen2Views);
      //   console.log(screen3Views);
      // }
    }
  },[currentPlaylist])

  useEffect(() => {
    if (currentPlaylist && currentPlaylist.views && screen0Views) {
      if (screen0Views.length > 1) {
        setTimeout(() => {
          const nextIndex = getNextIndex(nextViewIndex, screen0Views.length);
          setNextViewIndex(nextIndex);
          setView(screen0Views[nextIndex]);
        }, view.seconds * 1000);
      } else {
        setView(screen0Views[0])
      }
      // if (currentPlaylist.layout === "fullscreen") {
      //   if (sortedViews.length > 1) {
      //     setTimeout(() => {
      //       const nextIndex = getNextIndex(nextViewIndex, sortedViews.length);
      //       setNextViewIndex(nextIndex);
      //       setView(sortedViews[nextIndex]);
      //     }, view.seconds * 1000);
      //   } else {
      //     setView(sortedViews[0])
      //   }
      // } 
      // if (currentPlaylist.layout === "vertical-split-screen" || "t-split-screen-left" || "cross-split-screen") {
      //   if (screen0Views.length > 1) {
      //     setTimeout(() => {
      //       const nextIndex = getNextIndex(nextViewIndex, screen0Views.length);
      //       setNextViewIndex(nextIndex);
      //       setView(screen0Views[nextIndex]);
      //     }, view.seconds * 1000);
      //   } else {
      //     setView(screen0Views[0])
      //   }
      // }
    }
    // eslint-disable-next-line
  }, [currentPlaylist, view, nextViewIndex])

  useEffect(() => {
    if(currentPlaylist && currentPlaylist.views && screen1Views) {
      if (currentPlaylist.layout === "vertical-split-screen" || "t-split-screen-left" || "cross-split-screen") {
        if (screen1Views.length > 1) {
          setTimeout(() => {
            const nextIndex = getNextIndex(nextView1Index, screen1Views.length);
            setNextView1Index(nextIndex)
            setView1(screen1Views[nextIndex])
          }, view1.seconds * 1000);
        } else {
          setView1(screen1Views[0])
        }
      }
    }
  }, [currentPlaylist, view1, nextView1Index])

  useEffect(() => {
    if(currentPlaylist && currentPlaylist.views && screen2Views) {
      if (currentPlaylist.layout === "t-split-screen-left" || "cross-split-screen") {
        if (screen2Views.length > 1) {
          setTimeout(() => {
            const nextIndex = getNextIndex(nextView2Index, screen2Views.length);
            setNextView2Index(nextIndex)
            setView2(screen2Views[nextIndex])
          }, view2.seconds * 1000);
        } else {
          setView2(screen2Views[0])
        }
      }
    }
  }, [currentPlaylist, view2, nextView2Index])

  useEffect(() => {
    if(currentPlaylist && currentPlaylist.views && screen3Views) {
      if (currentPlaylist.layout === "cross-split-screen") {
        if (screen3Views.length > 1) {
          setTimeout(() => {
            const nextIndex = getNextIndex(nextView3Index, screen3Views.length);
            setNextView3Index(nextIndex)
            setView3(screen3Views[nextIndex])
          }, view3.seconds * 1000);
        } else {
          setView3(screen3Views[0])
        }
      }
    }
  }, [currentPlaylist, view3, nextView3Index])

  useEffect(() => {
    if (device_id && admin_id && location_id) {
      setLoading(true)
      GetDeviceById(device_id, admin_id, location_id)
        .then(response => {
          setLoading(false)
          try {
            setDevice(response?.data)
          } catch (error) {
            enqueueSnackbar(
              <Box sx={{ textAlign: 'center', padding: 1 }}>
                <Typography variant="h6">
                  <b>(Internal) {error?.message}</b>
                  {error?.response?.data?.detail?.message && (
                    <>
                      <br />
                      {error?.response?.data?.detail?.message}
                    </>
                  )}
                </Typography>
              </Box>,
              {
                variant: 'error',
                persist: true,
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                TransitionComponent: Fade
              }
            );
          }
        })
        .catch(error => {
          setLoading(false)
          const errorSnackbar = enqueueSnackbar(
            <Box sx={{ textAlign: 'center', padding: 1 }}>
              <Typography variant="h6">
                <b>(API) {error?.message}</b>
                {error?.response?.data?.detail?.message && (
                  <>
                    <br />
                    {error?.response?.data?.detail?.message}
                  </>
                )}
                <br />
                Retrying in 5 seconds...
              </Typography>
            </Box>,
            {
              variant: 'error',
              persist: true,
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
              TransitionComponent: Fade
            }
          );
          const intervalId = setInterval(() => {  // Assign interval to a variable to clear it.
            closeSnackbar(errorSnackbar)
            setLoadingText("Retrying...")
            setTimeout(() => {
              setRetry(!retry)
              clearInterval(intervalId)
            }, 200)
          }, 4800) // Actually interval for 4800ms so the snackbar closes in time
        })
    }
    // eslint-disable-next-line
  }, [retry])

  useEffect(() => {
    if (device) {
      const dataInterval = setInterval(() => {
        GetDeviceById(device_id, admin_id, location_id)
          .then(response => {
            setLoading(false)
            try {
              if (!isSame(response.data, device)) {
                window.location.reload(true)
              }
            } catch (error) {
              enqueueSnackbar(
                <Box sx={{ textAlign: 'center', padding: 1 }}>
                  <Typography variant="h6">
                    <b>(Internal) {error?.message}</b>
                    {error?.response?.data?.detail?.message && (
                      <>
                        <br />
                        {error?.response?.data?.detail?.message}
                      </>
                    )}
                  </Typography>
                </Box>,
                {
                  variant: 'error',
                  persist: true,
                  anchorOrigin: { vertical: 'top', horizontal: 'center' },
                  TransitionComponent: Fade
                }
              );
            }
          })
          .catch(error => {
            setLoading(false)
            const errorSnackbar = enqueueSnackbar(
              <Box sx={{ textAlign: 'center', padding: 1 }}>
                <Typography variant="h6">
                  <b>(API) {error?.message}</b>
                  {error?.response?.data?.detail?.message && (
                    <>
                      <br />
                      {error?.response?.data?.detail?.message}
                    </>
                  )}
                  <br />
                  Retrying in 5 seconds...
                </Typography>
              </Box>,
              {
                variant: 'error',
                persist: true,
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                TransitionComponent: Fade
              }
            );
            const intervalId = setInterval(() => {  // Assign interval to a variable to clear it.
              closeSnackbar(errorSnackbar)
              setLoadingText("Retrying...")
              setTimeout(() => {
                setRetry(!retry)
                clearInterval(intervalId)
              }, 200)
            }, 4800) // Actually interval for 4800ms so the snackbar closes in time
          })
      }, 10000)

      return () => clearInterval(dataInterval);
    }
    // eslint-disable-next-line
  }, [device])

  return (
    (query.size !== 0 && device_id && admin_id && location_id && device) ?
      <>
        <ThemeProvider theme={mainTheme}>
          <CssBaseline />
          <Box className={rotation}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}
            >
              {loading ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <CircularProgress color='inherit' />
                  <Typography variant="body1" sx={{ marginTop: 2 }}>
                    {loadingText ?? "Loading..."}
                  </Typography>
                </Box>
              ) : (
                scheduleEmpty ?
                  <ScheduleEmpty />
                  : 
                  <Splitscreen 
                    layout={layout}
                    aspect={aspect}
                    view={view}
                    view1={view1}
                    view2={view2}
                    view3={view3}
                  />
              )}
            </Box>
          </Box>
        </ThemeProvider>
      </>
      :
      <>
        <App />
      </>
  );
}

export default Player;
