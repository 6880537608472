import { Box, } from "@mui/material";
import NarrowcastingVideo from "./narrowcastingVideo";

export default function Splitscreen ({ layout, aspect, view, view1, view2, view3 }) {
    return (
        (layout === "fullscreen") ? 
            <>
                <Box sx={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw'
                }}>
                    {view?.content_type === "Video" && (
                        <NarrowcastingVideo view={view} fit={aspect? "contain" : "fill"} />
                    )}
                    {view?.content_type === "Image" && (
                        <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill"}} alt="image" src={view?.image} />
                    )}
                </Box>
            </> 
        : (layout === "vertical-split-screen") ?
            <>
                <Box sx={{
                    display: 'grid',
                    gap: '0',
                    height: '100vh',
                    width: '100vw',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridTemplateRows: 'repeat(2, 1fr)'
                }}>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '1', gridRow: 'span 2'}}>
                        {view?.content_type === "Video" && (
                            <NarrowcastingVideo view={view} fit={aspect? "contain" : "fill"} />
                        )}
                        {view?.content_type === "Image" && (
                            <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill"}} alt="image" src={view?.image} />
                        )}
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '2', gridRow: 'span 2'}}>
                        {view1?.content_type === "Video" && (
                            <NarrowcastingVideo view={view1} fit={aspect? "contain" : "fill"} />
                        )}
                        {view1?.content_type === "Image" && (
                            <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill"}} alt="image" src={view1?.image} />
                        )}
                    </Box>
                </Box>
            </>
        : (layout === "t-split-screen-left") ?
            <>
                <Box sx={{
                    display: 'grid',
                    gap: '0',
                    height: '100vh',
                    width: '100vw',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridTemplateRows: 'repeat(4, 1fr)' 
                }}>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '1', gridRow: 'span 4'}}>
                        {view?.content_type === "Video" && (
                            <NarrowcastingVideo view={view} fit={aspect? "contain" : "fill"} />
                        )}
                        {view?.content_type === "Image" && (
                            <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill"}} alt="image" src={view?.image} />
                        )}
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '2', gridRow: 'span 2'}}>
                        {view1?.content_type === "Video" && (
                            <NarrowcastingVideo view={view1} fit={aspect? "contain" : "fill"} />
                        )}
                        {view1?.content_type === "Image" && (
                            <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill"}} alt="image" src={view1?.image} />
                        )}
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '2', gridRow: 'span 2'}}>
                        {view2?.content_type === "Video" && (
                            <NarrowcastingVideo view={view2} fit={aspect? "contain" : "fill"} />
                        )}
                        {view2?.content_type === "Image" && (
                            <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill"}} alt="image" src={view2?.image} />
                        )}
                    </Box>
                </Box>
            </>
        :  (layout === "cross-split-screen") ?
            <>
                <Box sx={{
                    display: 'grid',
                    gap: '0',
                    height: '100vh',
                    width: '100vw',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridTemplateRows: 'repeat(4, 1fr)' 
                }}>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '1', gridRow: 'span 2'}}>
                        {view?.content_type === "Video" && (
                            <NarrowcastingVideo view={view} fit={aspect? "contain" : "fill"} />
                        )}
                        {view?.content_type === "Image" && (
                            <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill"}} alt="image" src={view?.image} />
                        )}
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '2', gridRow: 'span 2'}}>
                        {view1?.content_type === "Video" && (
                            <NarrowcastingVideo view={view1} fit={aspect? "contain" : "fill"} />
                        )}
                        {view1?.content_type === "Image" && (
                            <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill"}} alt="image" src={view1?.image} />
                        )}
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '1', gridRow: 'span 2'}}>
                        {view2?.content_type === "Video" && (
                            <NarrowcastingVideo view={view2} fit={aspect? "contain" : "fill"} />
                        )}
                        {view2?.content_type === "Image" && (
                            <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill"}} alt="image" src={view2?.image} />
                        )}
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '2', gridRow: 'span 2'}}>
                        {view3?.content_type === "Video" && (
                            <NarrowcastingVideo view={view3} fit={aspect? "contain" : "fill"} />
                        )}
                        {view3?.content_type === "Image" && (
                            <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill"}} alt="image" src={view3?.image} />
                        )}
                    </Box>
                </Box>
            </>
        : <>
            <h1>This layout is currently not supported</h1>
        </>
                /*() => {
            switch(layout){
                case "fullscreen":
                    return(
                        <>
                            {view?.content_type === "Video" && (
                                <NarrowcastingVideo {...view} />
                            )}
                            {view?.content_type === "Image" && (
                                <Box component="img" sx={{ position: 'absolute', height: "100%", width: "100%" }} alt="image" src={view?.image} />
                            )}
                        </>
                    );
                case "vertical-split-screen":
                    return(
                        <>
                            <Box sx={{
                                display: 'grid',
                                gap: '0',
                                height: '100vh',
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                gridTemplateRows: 'repeat(2, 1fr)'
                            }}>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '1', gridRow: 'span 2'}}>
                                    {view?.content_type === "Video" && (
                                        <NarrowcastingVideo {...view} />
                                    )}
                                    {view?.content_type === "Image" && (
                                        <Box component="img" sx={{height: "100%", width: "100%" }} alt="image" src={view?.image} />
                                    )}
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '2', gridRow: 'span 2'}}>
                                    {view1?.content_type === "Video" && (
                                        <NarrowcastingVideo {...view1} />
                                    )}
                                    {view1?.content_type === "Image" && (
                                        <Box component="img" sx={{height: "100%", width: "100%" }} alt="image" src={view1?.image} />
                                    )}
                                </Box>
                            </Box>
                        </>
                    );
                case "t-split-screen-left":
                    return(
                        <>
                            <Box sx={{
                                display: 'grid',
                                gap: '0',
                                height: '100vh',
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                gridTemplateRows: 'repeat(2, 1fr)' 
                            }}>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '1', gridRow: 'span 2'}}>
                                    {view?.content_type === "Video" && (
                                        <NarrowcastingVideo {...view} />
                                    )}
                                    {view?.content_type === "Image" && (
                                        <Box component="img" sx={{height: "100%", width: "100%" }} alt="image" src={view?.image} />
                                    )}
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '2', gridRow: 'span 1'}}>
                                    {view1?.content_type === "Video" && (
                                        <NarrowcastingVideo {...view1} />
                                    )}
                                    {view1?.content_type === "Image" && (
                                        <Box component="img" sx={{height: "100%", width: "100%" }} alt="image" src={view1?.image} />
                                    )}
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gridColumn: '2', gridRow: 'span 1'}}>
                                    {view2?.content_type === "Video" && (
                                        <NarrowcastingVideo {...view2} />
                                    )}
                                    {view2?.content_type === "Image" && (
                                        <Box component="img" sx={{height: "100%", width: "100%" }} alt="image" src={view2?.image} />
                                    )}
                                </Box>
                            </Box>
                        </>
                    );
                default:
                    return(
                        <>
                            <h1>something went wrong</h1>
                        </>
                    );
            }
        }*/
    )
}